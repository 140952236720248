import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'
import { PRICE_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'
import DeleteButton from '../_library/DeleteButton'
import { isNil } from 'lodash/lang'


export const PriceDialog = ({ price, aircraft, onClose }) => {
  
  const priceHooks = useFirestore(PRICE_COLLECTION)
  
  const [year, setYear] = useState(price.year || (new Date()).getFullYear())
  const [ferryHour, setFerryHour] = useState(price.ferryHour || 0)
  const [fix, setFix] = useState(price.fix || 0)
  const [fixCounter, setFixCounter] = useState(price.fixCounter || 0)
  const [fuelFreeFerry, setFuelFreeFerry] = useState(price.fuelFreeFerry || 0)
  const [fuelFreeHour, setFuelFreeHour] = useState(price.fuelFreeHour || 0)
  const [hour, setHour] = useState(price.hour || 0)
  const [submitted, setSubmitted] = useState(false)
  
  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...price,
      year: Number(year),
      fix: Number(fix),
      fixCounter: Number(fixCounter),
      hour: Number(hour),
      fuelFreeHour: Number(fuelFreeHour),
      ferryHour: Number(ferryHour),
      fuelFreeFerry: Number(fuelFreeFerry),
    }, val => !isNil(val))
    const handler = data => price.id ? priceHooks.updateDoc(price.id, data) : priceHooks.addDoc(data)
    return handler(obj)
      .then(onClose)
      .finally(() => setSubmitted(false))
  }
  
  return (
    <Dialog open onClose={onClose} component='form' onSubmit={handleSubmit}>
      <DialogTitle>Price</DialogTitle>
      <DialogContent>
        <Alert severity='info' variant='outlined'>
          Les prix sont calculés selon la formule suivante : <br />
          <code>fix + (duree + fixCounter) x hour</code> <br />
          Les taxes d&apos;atterrissage sont ensuite ajoutées et le vol est débté sur le compte du pilote ou le compte club selon le mode de facturation choisi.
        </Alert>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='dense'
              id='year'
              label='Year'
              fullWidth
              type='number'
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='fix'
              label='Fix'
              fullWidth
              type='number'
              value={fix}
              onChange={e => setFix(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='fixCounter'
              label='Fix Counter'
              fullWidth
              type='number'
              value={fixCounter}
              inputProps={{ step: aircraft.float }}
              onChange={e => setFixCounter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='hour'
              label='hour'
              fullWidth
              type='number'
              value={hour}
              onChange={e => setHour(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='fuelFreeHour'
              label='Fuel Free Hour'
              fullWidth
              type='number'
              value={fuelFreeHour}
              onChange={e => setFuelFreeHour(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='ferryHour'
              label='Ferry hour'
              fullWidth
              type='number'
              value={ferryHour || 0}
              onChange={e => setFerryHour(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='fuelFreeFerry'
              label='Fuel Free Ferry'
              fullWidth
              type='number'
              value={fuelFreeFerry}
              onChange={e => setFuelFreeFerry(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={submitted}>Submit</Button>
        {price.id && <DeleteButton deleteAction={() => priceHooks.deleteDoc(price.id).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

PriceDialog.propTypes = {
  price: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

export default PriceDialog
