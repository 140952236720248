import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem,
  Grid, CircularProgress, Typography, FormControlLabel, Checkbox, Link } from '@mui/material'
import { useSelector } from 'react-redux'
import { QUALIFICATION_COLLECTION, QUALIFICATION_TYPE_COLLECTION, ROLE_ADMIN, ROLE_INSTRUCTOR, USER_COLLECTION } from '../../_constants/globals'
import FileUpload from '../_library/FileUpload'
import UserAutocomplete from '../_library/UserAutocomplete'
import InlineEditor from '../_library/InlineEditor'
import InlineAvatarEditor from '../profile/InlineAvatarEditor'
import { pickBy } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const gdprEmail = 'En tant que membre ou participant aux activités de Midi-Pyrénées Voltige je certifie avoir pris connaissance le la politique de gestion des données personnelles mise en œuvre à MPV et déclare les accepter. Je m\'engage aussi à les mettre en œuvre si je suis dans la situation de devoir participer à un traitement des données qui me seraient confiées pour des activités dans le cadre de l\'association.'

const EditQualificationDialog = ({ onClose, qualification }) => {
  
  const profile = useAuth().getProfile()
  const qualificationTypeHooks = useFirestore(QUALIFICATION_TYPE_COLLECTION)
  const dbQualificationTypes = qualificationTypeHooks.getDocs()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const dbUsers = userHooks.getDocs()
  
  const [typeId, setTypeId] = useState(null)
  const [identValue, setIdentValue] = useState(null)
  const [grantDate, setGrantDate] = useState(null)
  const [expireDate, setExpireDate] = useState(null)
  const [attachmentUrl, setAttachmentUrl] = useState(null)
  const [pilot, setPilot] = useState(null)
  const [acceptRI, setAcceptRI] = useState(false)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  
  const selectedUser = useMemo(() => selectedUserId ? dbUsers.find(u => u.id === selectedUserId) : profile, [selectedUserId, dbUsers, profile])
  const type = useMemo(() => dbQualificationTypes.find(t => t.id === typeId), [typeId])
  
  const editQualification = useMemo(
    () => qualification && type && (!qualification.checkingDate || !type.fields.includes('expireDate')),
    [qualification, type],
  )
  
  const newQualificationTypes = useMemo(
    () => dbQualificationTypes?.filter(t => !dbQualifications?.some(q => q.typeRef.id === t.id && q.userRef.id === pilot?.id && !q.archived) || t.id === typeId),
    [dbQualificationTypes, dbQualifications, pilot?.id],
  )
  
  const validForm = useMemo(() => {
    if (!type) return false
    else if (type?.shortName === 'Cotisation' && !acceptRI) return false
    else if (type?.fields.includes('identValue') && !identValue) return false
    else if (type?.fields.includes('grantDate') && !grantDate) return false
    else if (type?.fields.includes('expireDate') && !expireDate && type.shortName !== 'Cotisation') return false
    else if (type?.fields.includes('attachmentUrl') && !attachmentUrl) return false
    else return true
  }, [type, acceptRI, identValue, grantDate, expireDate, attachmentUrl])
  
  useEffect(() => {
    if (qualification?.id && dbQualificationTypes) {
      setTypeId(qualification.typeRef.id)
      setIdentValue(qualification.identValue)
      qualification.grantDate && setGrantDate(qualification.grantDate)
      qualification.expireDate && setExpireDate(qualification.expireDate)
      !qualification.validated && setAttachmentUrl(qualification.attachmentUrl)
    }
  }, [qualification, dbQualificationTypes])
  
  useEffect(() => setPilot(selectedUser || profile), [selectedUser, profile])
  
  const handleSubmit = e => {
    e.preventDefault()
    if (type?.shortName === 'Cotisation' && !acceptRI)
      setErrors(prev => ({ ...prev, acceptRI: 'Il faut accepter le réglement intérieur pou s\'inscrire à MPV' }))
    if (type.fields.includes('identValue') && !identValue)
      setErrors(prev => ({ ...prev, identValue: 'Info requise' }))
    if (type.fields.includes('grantDate') && !grantDate)
      setErrors(prev => ({ ...prev, grantDate: 'Info requise' }))
    if (type.fields.includes('expireDate') && !expireDate && typeId.shortName !== 'Cotisation')
      setErrors(prev => ({ ...prev, expireDate: 'Info requise' }))
    if (type.fields.includes('attachmentUrl') && !attachmentUrl)
      setErrors(prev => ({ ...prev, attachmentUrl: 'Il faut que la validité puisse être vérifiée' }))
    if (validForm) {
      setSubmitted(true)
      return editQualification
        ? qualificationHooks.updateDoc(qualification.id, {
          ...pickBy({
            attachmentUrl,
            identValue,
            grantDate,
            expireDate,
          }),
        }).then(onClose)
        : qualificationHooks.addDoc({
          archived: false,
          typeRef: qualificationTypeHooks.getDocRef(typeId),
          userRef: userHooks.getDocRef(pilot.id),
          ...pickBy({
            attachmentUrl,
            identValue,
            grantDate,
            expireDate,
          }),
        }).then(onClose)
    }
  }
  
  const updateUser = data => userHooks.updateDoc(selectedUser.id, data)
  
  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0,0,0,.1)',
      },
    }}>
      <DialogTitle id='form-dialog-title'>{`${qualification ? 'Modifier' : 'Créer'} une validité`}</DialogTitle>
      <DialogContent>
        {!dbQualificationTypes
          ? <CircularProgress color='inherit' />
          : <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                {!editQualification && pilot && <UserAutocomplete
                  user={pilot}
                  label='Pilote'
                  set={setPilot}
                  disableClearable
                  disabled={!profile.roles?.includes(ROLE_ADMIN) && !profile.roles?.includes(ROLE_INSTRUCTOR)}
                  sx={{ width: '100%' }}
                />}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant='standard' fullWidth required>
                  <InputLabel id='type-select-label'>Type de validité</InputLabel>
                  <Select
                    variant='standard'
                    labelId='type-select-label'
                    id='type-select'
                    value={typeId || ''}
                    onChange={e => setTypeId(e.target.value)}
                    displayEmpty
                    disabled={Boolean(qualification)}
                  >
                    {newQualificationTypes.map(type =>
                      <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {type?.info && <Grid item xs={12}><p>{type.info}</p></Grid>}
              {type?.shortName === 'Cotisation' && <>
                <Grid item xs={12}>
                  <InlineEditor title='Email' value={selectedUser.email} handleSubmit={val => updateUser({ email: val })} />
                  <InlineEditor title='Téléphone' value={selectedUser.phone} handleSubmit={val => updateUser({ phone: val })} />
                  <Typography variant='body1'>Adresse</Typography>
                  <InlineEditor title='Rue' value={selectedUser.address?.street} handleSubmit={val => updateUser({ address: { ...selectedUser.address, street: val } })} />
                  <InlineEditor title='Code postal' value={selectedUser.address?.zipcode} handleSubmit={val => updateUser({ address: { ...selectedUser.address, zipcode: val } })} />
                  <InlineEditor title='Ville' value={selectedUser.address?.city} handleSubmit={val => updateUser({ address: { ...selectedUser.address, city: val } })} />
                  <InlineEditor title='Pays' value={selectedUser.address?.country} handleSubmit={val => updateUser({ address: { ...selectedUser.address, country: val } })} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>Une photo récente est pratique pour qu&apos;on se reconnaisse sur les terrains</Typography>
                  <InlineAvatarEditor />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptRI}
                        onChange={e => setAcceptRI(e.target.checked)}
                        color='primary'
                      />
                    }
                    label={
                      <Typography>
                        J&apos;accepte le&nbsp;
                        <Link
                          href='/reglement_interieur_mod4.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                          style={{ cursor: 'pointer' }}
                          underline='hover'>
                          réglement intérieur
                        </Link>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    J&apos;accepte la&nbsp;
                    <Link
                      href='/protection_des_donnees_v1.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                      download
                      style={{ cursor: 'pointer' }}
                      underline='hover'>
                      politque de protection des données
                    </Link>
                    &nbsp;en envoyant cet&nbsp;
                    <Link
                      href={`mailto:mpvcopil@gmail.com?subject=RGPD&body=${gdprEmail}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      download
                      style={{ cursor: 'pointer' }}
                      underline='hover'>
                      email
                    </Link>
                  </Typography>
                </Grid>
              </>}
              {type?.price && <Grid item xs={12}><p>Prix de la validité : {type.price} €</p></Grid>}
              {type?.fields.includes('identValue') &&
                <Grid item xs={12}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='identValue'
                    label='Identifiant'
                    fullWidth
                    value={identValue || ''}
                    onChange={e => setIdentValue(e.target.value)}
                    error={!!errors.identValue}
                    helperText={errors.identValue}
                  />
                </Grid>
              }
              {type?.fields.includes('grantDate') &&
                <Grid item xs={12}>
                  <DatePicker
                    variant='inline'
                    inputFormat='dd-MM-yyyy'
                    margin='dense'
                    id='grantDate-date'
                    label='Date de délivrance'
                    value={grantDate}
                    onChange={setGrantDate}
                    slotProps={{ textField: { error: !!errors.grantDate, helperText: errors.grantDate } }}
                  />
                </Grid>
              }
              {type?.fields.includes('expireDate') &&
                <Grid item xs={12}>
                  <DatePicker
                    variant='inline'
                    inputFormat='dd-MM-yyyy'
                    margin='dense'
                    id='expireDate-date'
                    label='Fin de validité'
                    value={expireDate}
                    onChange={setExpireDate}
                    disabled={type.shortName === 'Cotisation'}
                    slotProps={{ textField: { error: !!errors.expireDate, helperText: errors.expireDate } }}
                  />
                </Grid>
              }
              {type?.fields.includes('attachmentUrl') &&
                <Grid item xs={12}>
                  <FileUpload
                    folder={type.shortName}
                    placeholder='Photo du document'
                    onComplete={setAttachmentUrl}
                    fileUrl={attachmentUrl}
                    error={!!errors.attachmentUrl}
                    helperText={errors.attachmentUrl}
                  />
                </Grid>
              }
            </Grid>
          </form>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={!typeId || submitted}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditQualificationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  qualification: PropTypes.object,
}

export default EditQualificationDialog
