import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './store/rootReducer'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import packageFile from '../package.json'
import { loadReduxState } from './_helpers/localStorageHelper'
import theme from './theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import './index.css'
import './firebase'

window.version = packageFile.version

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadReduxState(),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
})

const container = document.getElementById('root')
const root = createRoot(container)

console.log("%c\n\n" +
  "███╗   ███╗ ███████╗ ██╗     ██╗   ██╗  \n" +
  "████╗ ████║ ██╔══██║ ╚██╗   ██╔╝ ██████╗\n" +
  "██╔████╔██║ ███████║  ╚██╗ ██╔╝  ╚═██╔═╝\n" +
  "██║╚██╔╝██║ ██╔════╝   ╚████╔╝     ╚═╝  \n" +
  "██║ ╚═╝ ██║ ██║         ╚██╔╝           \n" +
  "╚═╝     ╚═╝ ╚═╝          ╚═╝            \n\n" +
  "Open source project\n" +
  "Authored by Romain Coeur\n" +
  "", "font-family:monospace;color:#1976d2;font-size:12px;")

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
          <CssBaseline />
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
