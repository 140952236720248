import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton, Tooltip } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION, PRICE_COLLECTION } from '../../_constants/globals'
import MUIDataTable from 'mui-datatables'
import { Add, EditSharp } from '@mui/icons-material'
import DeleteButton from '../_library/DeleteButton'
import PriceDialog from './PriceDialog'


export const PriceCard = ({ aircraft }) => {
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const priceHooks = useFirestore(PRICE_COLLECTION, { storeAs: 'prices-' + aircraft.id })
  const prices = priceHooks.getDocs()
  
  const [selectedPrice, setSelectedPrice] = useState(null)
  
  useEffect(() => {
    priceHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraft.id)]],
      orderBy: [['year', 'asc']],
    })
    return priceHooks.unsubscribe
  }, [aircraft])
  
  const data = useMemo(() =>
    prices?.map(val => {
      const { year, fix, fixCounter, hour, fuelFreeHour, ferryHour, fuelFreeFerry } = val
      return [year, fix, fixCounter, hour, fuelFreeHour, ferryHour, fuelFreeFerry, val]
    }), [prices])
  
  return (<>
    <MUIDataTable
      data={data}
      columns={[
        {
          name: 'year',
          label: 'Year',
        },
        {
          name: 'fix',
          label: 'Fix',
        },
        {
          name: 'fixCounter',
          label: 'Fix counter',
        },
        {
          name: 'hour',
          label: 'Hour',
        },
        {
          name: 'fuelFreeHour',
          label: 'Fuel free hour',
        },
        {
          name: 'ferryHour',
          label: 'Ferry hour',
        },
        {
          name: 'fuelFreeFerry',
          label: 'Fuel free ferry',
        },
        {
          name: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            viewColumns: false,
            // eslint-disable-next-line
            customBodyRender: val => <>
              <Button onClick={() => setSelectedPrice(val)}><EditSharp /></Button>
              <DeleteButton deleteAction={() => priceHooks.deleteDoc(val.id)} />
            </>,
          },
        },
      ]}
      options={{
        elevation: 0,
        selectableRows: 'none',
        viewColumns: false,
        pagination: false,
        print: false,
        filter: false,
        search: false,
        download: false,
        customToolbar: () =>
          <Tooltip title='Add an item'>
            <span>
              <IconButton color='primary' onClick={() => setSelectedPrice({ aircraftRef: aircraftHooks.getDocRef(aircraft.id) })}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>,
      }}
    />
    {selectedPrice && <PriceDialog price={selectedPrice} aircraft={aircraft} onClose={() => setSelectedPrice(null)} />}
  </>)
}

PriceCard.propTypes = {
  aircraft: PropTypes.object.isRequired,
}

export default PriceCard
