import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, CardContent, Avatar, Box, CardHeader, IconButton, MenuItem, Menu } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { format, formatDistanceToNow } from 'date-fns'
import { History, Announcement, MoreVert, Money, Edit } from '@mui/icons-material'
import { selectAircraftId } from '../../store/actions/dataActions'
import { AIRCRAFTS } from '../../_constants/routes'
import PotentialGraph from './PotentialGraph'
import { useNavigate } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'
import { PRICE_COLLECTION } from '../../_constants/globals'


export const AircraftBigCard = ({ aircraft, sx = {} }) => {
  
  const navigate = useNavigate()
  const adminMode = useSelector(state => state.data.adminMode)
  const dispatch = useDispatch()
  const priceHooks = useFirestore(PRICE_COLLECTION)
  const prices = priceHooks.getDocs()
  
  const [anchorEl, setAnchorEl] = useState(null)
  
  const price = useMemo(() => prices?.find(p => p.aircraftRef.id === aircraft.id), [prices, aircraft])
  
  return (
    <Card sx={{ minWidth: '275px', mb: 4, p: 1, position: 'relative', ...sx }}>
      <CardHeader
        avatar={<Avatar alt={aircraft.name} src={aircraft.photoUrl} />}
        action={adminMode && <IconButton aria-label='settings' onClick={e => setAnchorEl(e.currentTarget)}><MoreVert /></IconButton>}
        title={aircraft.name}
        subheader={aircraft.type}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl || false}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => dispatch(selectAircraftId(aircraft.id)).then(() => navigate(AIRCRAFTS + '/management'))}>
          <Edit /> Edit
        </MenuItem>
      </Menu>
      <CardContent>
        {aircraft.comments && <Box sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
          <Typography variant='body1'><strong><Announcement sx={{ verticalAlign: 'bottom' }} />&nbsp;Remarques</strong></Typography>
          <Typography variant='body1'>{aircraft.comments}</Typography>
        </Box>}

        {aircraft.followUp && <Box sx={{ m: 1, mb: 4 }}>
          <Typography variant='body1' mb={1}><strong>Potentiel</strong></Typography>
          <PotentialGraph aircraft={aircraft} />
        </Box>}

        {aircraft.lastFlight && <Box sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
          <Typography variant='body1'><strong><History sx={{ verticalAlign: 'bottom' }} />&nbsp;Dernier
            vol</strong></Typography>
          <Typography variant='body1'>
            {formatDistanceToNow(aircraft.lastFlight.startDate, { addSuffix: true }) + ' (' + format(aircraft.lastFlight.startDate, 'dd-MM-yyyy') + ')'}
          </Typography>
          <Typography variant='body1'>
            {aircraft.lastFlight.pilot}{aircraft.lastFlight.instructor && ` avec ${aircraft.lastFlight.instructor}`}
          </Typography>
          {aircraft.lastFlight.type && <Typography variant='body1'>{aircraft.lastFlight.type.name}</Typography>}
          {aircraft.lastFlight.arrival && <Typography variant='body1'>à destination de {aircraft.lastFlight.arrival}</Typography>}
        </Box>}
        
        {aircraft.price && <Box sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 1, mt: 1 }}>
          <Typography variant='body1'><strong><Money sx={{ verticalAlign: 'bottom', mr: 1 }} />Couts</strong></Typography>
          <Typography variant='body1'>Heure de vol : {price?.hour} €</Typography>
          {price?.ferryHour && <Typography variant='body1'>Navigation : {price.ferryHour} €</Typography>}
        </Box>}

      </CardContent>
    </Card>
  )
}

AircraftBigCard.propTypes = {
  aircraft: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default AircraftBigCard
