import md5 from 'md5'

/**
 * Returns flight price
 *
 * @typedef Flight
 * @type {object}
 *
 * @property aircraft {object}
 * @property airport {object}
 * @property duration {number}
 * @property landings {number}
 * @property billing {string}
 *
 * @returns {string}
 */
export const getFlightPrice = flight => {
  if (!flight.billingType?.priceFormula || !flight.price) return 'NaN'
  else if (typeof flight.billingType.priceFormula === 'number')
    return flight.billingType.priceFormula
  const hydratedPriceFormula = flight.billingType.priceFormula
    .replace('AIRCRAFT_FIX', flight.price.fix)
    .replace('AIRCRAFT_HOUR', ['local', 'navigation'].includes(flight.flightType.slug) && flight.price.ferryHour || flight.price.hour)
    .replace('AIRCRAFT_FUEL_FREE_HOUR', ['local', 'navigation'].includes(flight.flightType.slug) && flight.price.fuelFreeFerry || flight.price.fuelFreeHour)
    .replace('LANDING_TAXES', flight.arrival.landingTax || 0)
    .replace('duration', flight.duration)
    .replace('landings', flight.landings)
  return eval(hydratedPriceFormula).toFixed(2) // eslint-disable-line no-eval
}

/**
 * get avatar from gravatar platform
 *
 * @param email
 * @returns {string}
 */
export const getGravatar = email => {
  const hash = email => md5(email || 'exemple@mail.com', { encoding: 'binary' })
  return `${window.location.protocol}//gravatar.com/avatar/${hash(email)}?d=identicon`
}

export const getObjectiveSymbol = objectiveString => {
  switch (objectiveString){
    case 'knowledge': return '/'
    case 'realised': return 'X'
    case 'skill': return 'O'
    case 'trainingNeeded': return '●'
  }
}

export const getImpressionSymbol = gradeString => {
  switch (gradeString){
    case 'unsatisfactory': return 'I'
    case 'satisfactory': return 'S'
    case 'efficient': return 'P'
  }
}
